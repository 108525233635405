import React from 'react';
import Layout from '../src/layouts/Layout';
import Page404 from '../src/views/Page404';

// ----------------------------------------------------------------------

export default function PageNotFound() {
  return (
    <Layout title="Oops" publicPage>
      <Page404 />
    </Layout>
  );
}
