import { useRouter } from 'next/router';
import Link from 'src/components/core/atoms/Link';
import { Logo } from 'src/components/core/atoms/Logo';
import { Button } from 'src/components/shad-base/button';
import { ROUTES } from 'src/config';
import typography from 'src/theme/typography';

// ----------------------------------------------------------------------

export default function Page404() {
  const router = useRouter();
  return (
    <div className="mt-10 flex w-full flex-col text-center">
      <div className="mb-16 flex w-full justify-center">
        <Logo />
      </div>
      <div>
        <p style={{ ...typography.h6 }}>404</p>
      </div>
      <div>
        <p style={{ ...typography.h5 }}>Page not found.</p>
      </div>
      <div className="mt-20">
        <Link href="/" color='primary.main'>
          <Button
            variant="outline"
            size="lg"
            onClick={() => {
              router.push(ROUTES.ROOT);
            }}
          >
            Go to Home
          </Button>
        </Link>
      </div>
    </div>
  );
}
